import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  h3 {
    color: #fff;
    text-align: center;
    padding: 50px 10px;
  }
`

const StyledMessage = styled.h3`
  color: ${({ theme }) => theme.colors.grayscale.white};
  text-align: center;
  padding: 50px 10px;
  max-width: 30em;

  small {
    display: block;
    font-size: 0.75em;
    font-weight: 400;
    margin-top: 2em;
  }

  a {
    color: ${({ theme }) => theme.colors.component.link};
  }
`

export interface FullPageErrorProps {
  children: React.ReactNode
  'data-cy'?: string
}

const FullPageError: React.FC<FullPageErrorProps> = ({ children, 'data-cy': dataCy }) => (
  <StyledContainer data-cy={dataCy}>
    <StyledMessage>{children}</StyledMessage>
  </StyledContainer>
)

export default FullPageError
